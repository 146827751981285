<template>
  <v-dialog :value="visible" max-width="1000px" @click:outside="hide">
    <v-card>
      <v-card-title>
        <span class="headline">Duyurular</span>
      </v-card-title>

      <v-card-text>
        <v-expansion-panels hover :value="0" v-if="announcements.length > 0">
          <v-expansion-panel v-for="(a, i) in announcements" :key="i">
            <v-expansion-panel-header class="font-weight-medium">{{ a.title }}</v-expansion-panel-header>
            <v-expansion-panel-content v-html="a.content" style="padding: 0 24px 16px" />
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else>
          Duyuru yok
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="hide">
          Kapat
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ["announcements"],
  methods: {
    ...mapMutations("announcement", ["hide"])
  },
  computed: {
    ...mapGetters("announcement", ["visible"])
  }
};
</script>
